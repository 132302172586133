import { http } from '../utils/http';

const publicPath = '/api/driver-center/driver/mine';
const resourcePath = '/api/resource';

// 获取个人信息
export const getUserInfo = async params => {
  return await http.post(`${publicPath}/driver/getDriverBaseInfo`, params);
};

// 获取合同列表
export const getConstractList = async params => {
  return await http.post(`${publicPath}/contract/searchDriverContractList`, params, false);
};

// 未签约合同列表
export const apiUnsignList = async params => {
  return await http.post(`${resourcePath}/app/contract/carrier/onlineUnsignList`, params, false);
};

// 未签约合同详情
export const apiUnsignDetail = async params => {
  return await http.post(`${resourcePath}/app/contract/carrier/onlineUnsignContractDetail`, params, false);
};

// 发起电子签约
export const apiSignConstruct = async params => {
  return await http.post(`${resourcePath}/app/contract/carrier/onlineSign`, params, false);
};

// 按批次号查询合同
export const apiGetConListByBatch = async params => {
  return await http.post(`${resourcePath}/open/h5/carrier/contract/batchList`, params, false);
};

// 按合同 号获取详情或者url
export const apiConDetail = async params => {
  return await http.post(`${resourcePath}/open/h5/carrier/contract/detail`, params, false);
};

/* BANK */

// 获取银行卡列表
export const getBankList = async params => {
  return await http.post(`${publicPath}/bank/searchBankCardList`, params, false);
};

// 查询所有银行
export const apiGetAllBank = async params => {
  return await http.post(`${publicPath}/bank/searchAllBank`, params, false);
};

// 添加银行卡
export const apiAddBankCard = params => {
  return http.post(`${publicPath}/bank/addBankCard`, params, false);
};

// 改变默认银行卡
export const apiChangeDefaultBank = params => {
  return http.post(`${publicPath}/bank/updateDefaultBankCard`, params, false);
};

// 银行卡解绑
export const apiUnbindBank = params => {
  return http.post(`${publicPath}/bank/removeBankCard`, params, false);
};

// 校验在线账户是否开通
export const apiCheckVirtualAccountSuccess = async params => {
  return http.post(`${publicPath}/bank/checkVirtualAccountSuccess`, params, false);
};

// 校验车队长在线账户是否开通
export const apiCheckCarrierVirtualAccountSuccess = async params => {
  return http.post(`${publicPath}/bank/checkCarrierVirtualAccountSuccess`, params, false);
};

// 查询个人银行卡列表
export const apiSearchCreateBillPayee = async params => {
  return await http.post(`${publicPath}/bank/searchCreateBillPayee`, params, false);
};
// 查询车队长银行卡列表
export const apiSearchCreateBillCarrierPayee = async params => {
  return await http.post(`${publicPath}/bank/searchCreateBillCarrierPayee`, params, false);
};

/* CAR */

// 获取车辆列表
export const apiGetCarList = async params => {
  return await http.post(`${publicPath}/truck/searchTruck`, params, false);
};

// 车辆认证
export const apiTruckAuth = async params => {
  return await http.post(`${publicPath}/truck/truckAuth`, params, false);
};

// 修改默认车辆
export const apiUpdateDefaultTruck = async params => {
  return await http.post(`${publicPath}/truck/updateDefaultTruck`, params, false);
};

// 更新车辆
export const apiUpdateCarDetail = async params => {
  return await http.post(`${publicPath}/truck/updateTruckInfo`, params, false);
};

// 查询是否已是审核车辆
export const apiCheckCarNumber = async params => {
  return await http.post(`${publicPath}/truck/verifyCarLicensePlate`, params, false);
};

export const apiAddTruckByCarNumber = async params => {
  return await http.post(`${publicPath}/truck/addCarLicensePlate`, params, false);
};

export const apiGetCarType = async params => {
  return await http.post(`${resourcePath}/truck/allCarType`, params, false);
};

export const apiGetTrailerType = async params => {
  return await http.post(`${resourcePath}/trailer/allTrailerType`, params, false);
};

export const apiWithdrawTruck = async params => {
  return await http.post(`${publicPath}/truck/withdrawAuditTruck`, params, false);
};

export const apiGetCarDetail = async params => {
  return await http.post(`${publicPath}/truck/getTruckById`, params, false);
};

// 获取车辆信息
export const apiGetTruckDetail = async params => {
  return await http.post(`${publicPath}/truck/getTruckById`, params, false);
};

/* 承运人 */
// 获取承运人列表
export const apiGetBroker = async params => {
  return await http.post(`${publicPath}/carrier/searchCarrierInfoList`, params, false);
};

/* 承运人 */
// 获取承运人列表
export const apiGetNewAppVersionByWeb = async params => {
  return await http.post(`/api/basic-ability/open/getNewAppVersionByWeb`, params, false);
};

// 1.3.4
// 司机修改车辆
export const updateTruckInfoApi = async params => {
  return await http.post(`${publicPath}/truck/updateTruckInfo`, params, false);
};

// 车辆重新认证
export const apiAfreshCarDetail = async params => {
  return await http.post(`${publicPath}/truck/afreshTruckAuth`, params, false);
};

// 签收委托代收合同
export const entrustSignMsmAgreeApi = async params => {
  return await http.post(`${resourcePath}/open/contract/entrust/entrustSignMsmAgree`, params, false);
};

// 承运人合同签收
export const signMsmAgreeApi = async params => {
  return await http.post(`${resourcePath}/open/contract/carrier/signMsmAgree`, params, false);
};

// 未签约合同列表
export const apiNeedLoadPic = async params => {
  return await http.post(`${resourcePath}/app/contract/carrier/needLoadingUnloadingCargoPictures`, params, false);
};

// 黑名单
export const verifyBlackAPi = async params => {
  return await http.post(`${resourcePath}/platform/black/verifyBlackInfo`, params, false);
};

// 获取个人信息
export const getPlatformAndCompanyAuditConfig = async params => {
  return await http.post(`${publicPath}/config/getPlatformAndCompanyAuditConfig`, params, false);
};

export const getTrailerTypesApi = () => http.post('/api/cyy-center/open/cyy/platform/manage/allRTrailerType');

export const getLengthAxlesMapApi = () => http.post('/api/resource/truck/open/getCarLoadLengthToAxlesMapping');

export const getLengthRecommendApi = () => http.post('/api/resource/truck/open/getCarRecommendLength');
